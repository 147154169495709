import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import formatCurrency from "../../core/helpers/format-currency.helper";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import { useQuery } from "@tanstack/react-query";
import UserDebtsService from "../../core/service/user-debts.service";
import { useAuth } from "../../core/context/auth.context";
import AccumulatedDebtInterface from "@homegame/common/dist/interface/accumulated-debt.interface";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UserDebtInterface from "@homegame/common/dist/interface/user-debt.interface";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";
import DebtType from "@homegame/common/dist/enum/debt-type.enum";

interface DebtInfoDialogProps {
  type?: "users" | "to-user";
  debt: AccumulatedDebtInterface;
  handleDebtDialogClose: () => void;
  open: boolean;
}

interface DebtHistoryEntry extends UserDebtInterface {
  description: string;
  amountLost: number;
  amountWon: number;
  total: number;
  gameId: number;
  currency: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableHead: {
      color: "#fff",
      textShadow: "none",
      fontSize: 13,
      padding: 5,
      lineHeight: "18px",
      verticalAlign: "bottom",
    },
    tableItem: {
      color: "#fff",
      textShadow: "none",
      fontSize: 15,
      padding: 5,
    },
  }),
);

export default function DebtInfoDialogComponent({
  debt,
  handleDebtDialogClose,
  type,
  open,
}: DebtInfoDialogProps) {
  const classes = useStyles({});
  const userDebtsService = new UserDebtsService();
  const { user } = useAuth();

  const whereOpts = {
    fromUserId: type === "users" ? user!.id! : debt.fromUserId,
    toUserId: type === "users" ? debt.toUserId : user!.id!,
    tableId: debt.tableId,
  };

  const { data: userDebtsInfo } = useQuery({
    queryKey: [`debtsHistoryItems1`, whereOpts],
    queryFn: () => userDebtsService.userDebts(whereOpts),
    initialData: [],
  });

  let totalBalance = 0;

  const entries: DebtHistoryEntry[] = [];

  const getDebtDescriptionText = (
    debt: UserDebtInterface,
    isPaid = false,
  ): string => {
    const { type, fromUserId } = debt;

    const isViewerDebtor = fromUserId === user!.id;

    const { name: debtorName } = debt.fromUser!;
    const { name: creditorName } = debt.toUser!;

    if (isPaid) {
      return isViewerDebtor
        ? `I paid ${creditorName}`
        : `${debtorName} paid me`;
    }

    return type === DebtType.LOSS
      ? isViewerDebtor
        ? `I lost in game to ${creditorName}`
        : `I won in game from ${debtorName}`
      : isViewerDebtor
        ? `I won in game from ${creditorName}`
        : `I lost in game to ${debtorName}`;
  };

  const getDebtHistoryEntries = (debt: UserDebtInterface) => {
    const isViewerDebtor = debt.fromUserId === user!.id;
    const isViewerCreditor = debt.toUserId === user!.id;
    const isLoss = debt.type === DebtType.LOSS;

    if (debt.type === DebtType.WIN || isLoss) {
      let existingEntry = entries.find((entry) => entry.gameId === debt.gameId);

      const sign = isViewerDebtor ? -1 : 1;
      totalBalance += sign * debt.amount;

      if (existingEntry) {
        if (isLoss) {
          existingEntry.amountLost += isViewerDebtor ? debt.amount : 0;
          existingEntry.amountWon += isViewerCreditor ? debt.amount : 0;
        } else {
          existingEntry.amountWon += isViewerDebtor ? Math.abs(debt.amount) : 0;
          existingEntry.amountLost += isViewerCreditor
            ? Math.abs(debt.amount)
            : 0;
        }
        existingEntry.total += sign * debt.amount;
      } else {
        entries.push({
          ...debt,
          description: getDebtDescriptionText(debt),
          amountLost: isLoss
            ? isViewerDebtor
              ? debt.amount
              : 0
            : isViewerCreditor
              ? Math.abs(debt.amount)
              : 0,
          amountWon: isLoss
            ? isViewerCreditor
              ? debt.amount
              : 0
            : isViewerDebtor
              ? Math.abs(debt.amount)
              : 0,
          total: totalBalance,
        });
      }
    }

    if (debt.status === UserDebtStatus.PAID) {
      const payAmount = Number(debt.payedAmount) || 0;
      totalBalance += isViewerDebtor ? payAmount : -payAmount;

      entries.push({
        ...debt,
        createdAt: debt.payedAt,
        description: getDebtDescriptionText(debt, true),
        amountWon: isViewerDebtor ? payAmount : 0,
        amountLost: isViewerCreditor ? payAmount : 0,
        total: 0,
      });
      return;
    }
  };

  userDebtsInfo.forEach(getDebtHistoryEntries);

  entries.reverse();

  const totalBalanceString = () => {
    const isCreditorView = debt.toUserId === user!.id;
    const amount = debt.accumulatedDebt;
    const isPositive = amount > 0;
    const isZero = amount === 0;

    let color = isZero
      ? "#47528c"
      : isCreditorView === isPositive
        ? "green"
        : "red";
    let sign = isZero ? "" : isCreditorView === isPositive ? "+" : "-";

    return (
      <span style={{ color }}>
        {sign}
        {formatCurrency(Math.abs(amount), debt.currency)}
      </span>
    );
  };

  return (
    <Dialog
      onClose={handleDebtDialogClose}
      open={open}
      sx={{
        "& .MuiPaper-root": { backgroundColor: "#aab1db" },
        "& .MuiDialogTitle-root": {
          color: "#47528c",
          textShadow: "none",
          textAlign: "center",
          fontWeight: 400,
          marginTop: "10px",
        },
      }}
    >
      <DialogTitle>
        {type === "users" && `Me/${debt.toUser?.name} balance`}
        {type === "to-user" && `${debt.fromUser?.name}/Me balance`} (
        {totalBalanceString()})
        <br />
        <small style={{ color: "white" }}>In {debt.table.name}</small>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleDebtDialogClose}
        sx={{
          position: "absolute",
          right: 0,
          top: -5,
          color: "#47528c",
          textShadow: "none",
          boxShadow: "none",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: 15 }}>
        <Grid container spacing={2}>
          {userDebtsInfo.length > 0 && (
            <>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{}}>
                      <TableCell
                        className={classes.tableHead}
                        style={{ paddingLeft: 10 }}
                        align="left"
                      >
                        Description
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        I lost in
                        <br />
                        game/Paid
                        <br />
                        to me
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        I won in
                        <br />
                        game/Paid <br />
                        by me
                      </TableCell>
                      <TableCell className={classes.tableHead} align="left">
                        Total
                        <br />
                        balance
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entries.map((entry) => (
                      <TableRow
                        key={dayjs(entry.createdAt).valueOf() + entry.total}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className={classes.tableItem}
                          style={{ fontSize: 12, paddingLeft: 10 }}
                          align="left"
                        >
                          <b>{dayjs(entry.createdAt).format("M.D.YY")}</b>
                          <br />
                          {entry.description}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {entry.amountLost > 0
                            ? `${formatCurrency(
                                entry.amountLost,
                                entry.currency,
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {entry.amountWon > 0
                            ? `${formatCurrency(
                                entry.amountWon,
                                entry.currency,
                              )}`
                            : ""}
                        </TableCell>
                        <TableCell className={classes.tableItem} align="left">
                          {formatCurrency(entry.total, entry.currency)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
